@import 'init/clearfix';



.hide,
.visually-hidden{display:none;}


.element-invisible {
    position: absolute !important;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    overflow: hidden;
    height: 1px;
}



*:focus{
	outline:none;
}

img {
    border: 0;
    max-width: 100%;
}

table{
	width:100%;
}



