 aside.sidebar-first{
	border-right: 1px solid $table-border-color;
	ul.menu{
		a{
			@extend .pl-4;
			@extend .py-2;
			@extend .py-lg-3;
			display:block;
			color:$navbar-light-color;
			&:hover{
				text-decoration:none;
				color:$navbar-light-hover-color;
			}
			&.active-trail,
			&.active{
				color: $navbar-light-active-color;
			}
		}
		
		li{
			border-bottom: 1px solid $table-border-color;
			&:last-child{
				border: 0 none;
			}
		}
	}
	
	
	//es werden alle menupinkte ab level 1 eingeblendet im menu block, daher die erste ebene, die nicht activ ist, ausblenden
	//grund: wir wollen den Aktiven Menupunkt der ersten Ebene oben drüber haben
	.menu-block-wrapper.menu-name-main-menu{
		> ul.menu > li{
			display:none;
			border: 0 none;
			&.is-active-trail{
				display:block;
				> a{
					font-weight:600;
					border-bottom:2px solid $primary-color;
					@extend .mb-1,.mb-lg-2;
					@extend .pl-0;
				}
			}
		}
	}
	
	
}


aside{

	.block{
		@extend .mb-2;
		@extend .mb-lg-5;
		.block__title{
			@include fancy-headline;
			@extend .mb-3;
			@extend .pt-3, .pb-2;
			font-size: $h3-font-size;
		}
	}


}

aside.sidebar-second{



	
	//make sidebar second a row, so that blocks align on smaller screens
	@extend .row;
	@extend .mt-4, .mt-md-0;
	align-content: flex-start;
	.block{
		@extend .col-12;
		@extend .col-sm-6;
		@extend .col-md-12;
	}
}

//border rechts bei main
main.layout-both-sidebars,
main.layout-second-sidebar{
	border-right: 1px solid $table-border-color;
}

@include media-breakpoint-down(md) {
	aside.sidebar-first{
		border-right: 0 none;
		border-top: 1px solid darken($table-border-color,30%);
		ul.menu{
			margin-bottom:0;
		}
	}
	main{
		border: 0 none;
	}
}