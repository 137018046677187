
.breadcrumb__title	{
	display:none;
}

body.front .breadcrumb-nav{
	display:none !important;
}


body.section-article.page-views .breadcrumb-nav,
body.page-file .breadcrumb-nav,
body.node-type-article .breadcrumb-nav{
	display:none !important;
}


body.not-front .breadcrumb-nav{
	ol.breadcrumb{
		@extend .m-0, .py-0;
		color: $text-muted;
		font-size: $font-size-sm;
		background:0 none;
	}
}

.breadcrumb-nav{
	display:none;
	@extend .d-lg-block;
}