//



@mixin nav-links{
	
	&.before-dropdown-toggle{ 	
		display:inline-block;	
		padding-right:0px !important;
	}
	&.dropdown-toggle{ 			
		display:inline-block;	
		padding-left:3px !important;
	}
}


li.nav-item a{
	@include nav-links;
	white-space:nowrap;
}

li.nav-item:hover{
	.dropdown-toggle.nav-link{
		color:$navbar-light-hover-color;
	}
}


li.nav-item{
	@extend .mx-1;
}


//logo
.navbar-brand{
	display:flex;
	align-items:center;
	.logo-name{color:$body-color;}
	img{
		max-width: 90px;
		padding-right: 1rem;
	}
	span{
		font-weight:700;
		font-size:1.4rem;
	}
}
 
.header{  
	// font-family: Roboto, Helvetica, Arial, sans-serif;
	font-family: Open Sans, Helvetica, Arial, sans-serif;
	font-size:$header-font-size;
	font-weight: 600;
	border-bottom:1px solid $table-border-color;
	color: lighten($body-color,10%);

	nav{
		@extend .px-0;
		@extend .py-2;
		@extend .py-lg-4;
	}
	

	
	.menu-wrapper{
		@extend .ml-auto;
		@extend .align-items-center;
	}
	
	.menu-right-wrapper{
		@extend .ml-5;
		@extend .d-none;
		@extend .d-lg-block;
	}
	
	.navbar-collapse{
		justify-content: flex-end;
	}
}



.navbar .menu-right-wrapper .dropdown-menu-right{
	right:0;
	left:auto;
}


//aufklappmenu nur bei großen viewpoints per hover
@include media-breakpoint-up(lg) { 
	.header .dropdown:hover{
		.dropdown-menu{
			display: block;
			margin-top:0;
		}
	}
}

.navbar-toggler{
	border:0 none;
	&:focus{
		outline: 0 none;
	}

}

.navbar-toggler.times[aria-expanded="true"]{}
.navbar-toggler.times[aria-expanded="false"]{display:none;}


.navbar-toggler.hamburger[aria-expanded="true"]{display:none;}
.navbar-toggler.hamburger[aria-expanded="false"]{}
.navbar-toggler{
	color: $gray-500;
}



.navbar-collapse.collapsing,
.navbar-collapse.show{

	@extend .mt-2;
	
	.dropdown-toggle{
		display:none;
	}
	
	.header-menu.navbar-nav{
		// border-top:1px solid darken($table-border-color,25%);
		border-top:1px solid $table-border-color;
	}
	
	a.nav-link{
		@extend .pt-2;
		@extend .pb-0;
	}
	
	li{
		a{font-weight: 600;}
		li a{font-weight: 500;}
	}
	
	.dropdown-menu{
		display:block;
		border:0 none;
		@extend .pt-0;
		@extend .pb-2;
		@extend .ml-4;
	}
}




.header-top-wrapper{
	background-color: darken($primary-color,0%);
	height: 5px;
    display: flex;
    align-items: center;
	
	.header-top{
		@extend .d-flex;
		@extend .flex-row-reverse;
	}
	
	#block-search-form{
	
	
		.form-actions.form-wrapper{
			padding:0 !important;
			border:0 none;
			background-color:transparent;
			margin:0 !important;
		}
		
		.form-group{
			margin:0;
		}
		
		.btn{
			color:white;
		}
		
		form .container-inline{
			display:flex;
			.form-type-textfield {
				input{
					margin:0;
					// border-top-right-radius:0;
					// border-bottom-right-radius:0;
				}
			}
			.form-actions{
				.form-submit{display:none;}
				button{
					border-top-left-radius:0;
					border-bottom-left-radius:0;
				}
			}
		}
	
	}
}


@include media-breakpoint-down(lg) { 
	.header .navbar-brand{
		.logo-name-stiftung{
			display:flex;
			flex-direction:column;
			span{
				font-size: 1.3rem;
				line-height: 1.6rem;
			}
		}
	}
}

@include media-breakpoint-down(xs) { 
	.header .navbar-brand{
		.logo-name-stiftung{
			span{
				font-size: 1rem;
				line-height: 1.1rem;
			}
		}
		img{
			max-width:100px;
		}
	}
}


