
$html-font-size: 14px;
// $html-font-size: 12px;
 
html { font-size: $html-font-size; } 

//font
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700");
@import url("https://fonts.googleapis.com/css?family=Roboto:400,300,500,700");


// $enable-rounded:            false !default;
// $enable-shadows:            false !default;
// $enable-gradients:          false !default;
// $enable-transitions:        true !default;
// $enable-hover-media-query:  false !default;
// $enable-grid-classes:       true !default;
// $enable-print-styles:       false !default;


//variables
$font-family-sans-serif:  'Open Sans', sans-serif;
$primary-color: #f4920d;
$grid-gutter-width: 60px;






$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg:                ($font-size-base * 1.25) !default;
$font-size-sm:                ($font-size-base * .875) !default;

$h1-font-size:                $font-size-base * 1.8 !default;
$h2-font-size:                $font-size-base * 1.6 !default;
$h3-font-size:                $font-size-base * 1.45 !default;
$h4-font-size:                $font-size-base * 1.3 !default;
$h5-font-size:                $font-size-base * 1.1 !default;
$h6-font-size:                $font-size-base !default;

 
$display1-size:               4rem !default;
$display2-size:               4.5rem !default;
$display3-size:               3.5rem !default;
$display4-size:               2.5rem !default;


$headings-font-family:   inherit !default;
$headings-font-weight:   100 !default;
$headings-line-height:   1.1 !default;
$headings-color:         inherit !default;

$paragraph-margin-bottom: 1.64286rem;


//
// Color system
//

$white:  #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:  #000 !default;

$grays: (
  100: $gray-100,
  200: $gray-200,
  300: $gray-300,
  400: $gray-400,
  500: $gray-500,
  600: $gray-600,
  700: $gray-700,
  800: $gray-800,
  900: $gray-900
) !default;

$blue:    #007bff !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;

$colors: (
  blue: $blue,
  indigo: $indigo,
  purple: $purple,
  pink: $pink,
  red: $red,
  orange: $orange,
  yellow: $yellow,
  green: $green,
  teal: $teal,
  cyan: $cyan,
  white: $white,
  gray: $gray-600,
  gray-dark: $gray-800
) !default;

$theme-colors: (
  primary: $primary-color,
  secondary: $gray-600,
  success: $green,
  info: $cyan,
  warning: $yellow,
  danger: $red,
  light: $gray-200,
  dark: $gray-800
) !default;




//generell
// $body-bg:                   #D0E69D !default;
$body-bg:                   #dae163 !default;
// $body-bg:                   #e9eeb8 !default;
// $body-bg:                   #bbd030 !default;
$body-color:                $gray-800 !default;
$content-bg-color: #ffffff;
$content-box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);


$header-transition: all 0.4s cubic-bezier(.7, 1, .7, 1);
$header-font-size: 15px;

$navbar-padding-y:1.5rem;
$grid-gutter-width:30px;



//navbar colors
$navbar-light-color:                #474950;
$navbar-light-hover-color:          $primary-color;
$navbar-light-active-color:         $primary-color;




//inputs
$input-bg: $gray-100;



$footer-bg-color: #ffffff;



@import 'init';
@import '../bootstrap-4.0.0/scss/bootstrap';



$fa-font-path: "../fonts/fontawesome-pro-5.3.1/webfonts" !default;
@import '../fonts/fontawesome-pro-5.3.1/scss/fontawesome';
@import '../fonts/fontawesome-pro-5.3.1/scss/solid';
@import '../fonts/fontawesome-pro-5.3.1/scss/light';
@import '../fonts/fontawesome-pro-5.3.1/scss/regular';

$border-color: $table-border-color;
@import 'custom/custom';






//lists
li,
ul {@extend .list-unstyled;}


//tables
table{
	@extend .table;
	th{
		border-top-width:0px;
	}
}






img{
	@extend .img-fluid;
}









