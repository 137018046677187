

 
.contact-info{
	.map-block{
		display:none;
		@extend .mb-4;
	}
	.address-block > div{
		margin-bottom:1rem;
		.icon{
			float:left;
			width:20px;
		}
		.text{
			float:left;
		}
	}
} 
 
 
.fancy-title,
.fancy-headline{
	@include fancy-headline;
} 
 


.card-header h1,
.card-header h2,
.card-header h3,
.card-header h4,
.card-header h5{
	margin:0;
}


body.drag {
  cursor: move;
}
.draggable a.tabledrag-handle {
  cursor: move;
  float: left; /* LTR */
  height: 1.7em;
  margin-left: -1em; /* LTR */
  overflow: hidden;
  text-decoration: none;
}
a.tabledrag-handle:hover {
  text-decoration: none;
}

a.tabledrag-handle .handle {
  background: url(../../../../../misc/draggable.png) no-repeat 6px 9px;
  height: 13px;
  margin: -0.4em 0.5em; /* LTR */
  padding: 1.42em 1.5em;
  width: 13px;
}
a.tabledrag-handle-hover .handle {
  background-position: 6px -11px;
}

tr.drag-previous {
    background-color: #ffd;
}

div.indentation {
    float: left;
    height: 1.7em;
    margin: -0.4em 0.2em -0.4em -0.4em;
    padding: 0.42em 0 0.42em 0.6em;
    width: 20px;
}

/**
 * TableDrag behavior.
 *
 * @see tabledrag.js
 */
tr.drag {
  background-color: #fffff0;
}

tr.drag-previous {
  background-color: #ffd;
}

.tabledrag-toggle-weight {
  font-size: 0.9em;
}

.draggableviews-changed-notice.messages{
	@extend .alert;
	&.warning{
		@extend .alert-warning;
	}
}



.ajax-progress.ajax-progress-throbber{
	background-color: #d6d6d64a;
    position: fixed;
    width: 100px;
    height: 100px;
    top: 50%;
    left: 50%;
    border-radius: 5px;
    display: flex;
    align-items: center;
    z-index: 1000;
    justify-content: center;
}


.ajax-progress .throbber {
    background: transparent url(../images/throbber.svg) no-repeat 0px center;
    // background: transparent url(../../../../../misc/throbber-active.gif) no-repeat 0px center;
    height: 15px;
    width: 15px;
}


	.tabs{
		@extend .mt-3;
		@extend .mb-3;
		@extend .nav;
		@extend .nav-pills;
		.tabs__tab{
			@extend .nav-item;
			
			a{
				@extend .nav-link;
				padding: 0.25rem 0.5rem;
				font-size: $font-size-sm;
			}
		}
	}







ul.fancy-ul{
	@include fancy-ul;
}








ol.fancy-ol {
  counter-reset: my-badass-counter;
}
ol.fancy-ol li:before {
  background-color: #4695d5;
  color: #FFFFFF;
  content: counter(my-badass-counter,decimal);
  counter-increment: my-badass-counter;
  font-family: Arial,Verdana,sans-serif;
  font-size: 10px;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  line-height: 20px;
  position: absolute;
  text-align: center;
  top: 0px;
}
ol.fancy-ol li {
  padding-left: 30px;
  position: relative;
  list-style: none outside none;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
ol.fancy-ol li:last-child {
  border: 0 none;
}

ul.fancy-ul-bordered li {
  border-bottom: 1px solid #bababa;
}
ul.fancy-ul-bordered li:before {
  font-size: 14px;
  font-family: 'Font Awesome 5 Solid';
  color: #4695d5;
  content: "\f138";
  position: absolute;
  left: 0px;
}
ul.fancy-ul-bordered li {
  padding-left: 21px;
  position: relative;
  list-style: none outside none;
  margin-bottom: 7px;
  padding-bottom: 7px;
}
ul.fancy-ul-bordered li:last-child {
  border: 0 none;
}

ol.fancy-ol-bordered {
  counter-reset: my-badass-counter;
}
ol.fancy-ol-bordered li {
  border-bottom: 1px solid #bababa;
}
ol.fancy-ol-bordered li:before {
  background-color: #4695d5;
  color: #FFFFFF;
  content: counter(my-badass-counter,decimal);
  counter-increment: my-badass-counter;
  font-family: Arial,Verdana,sans-serif;
  font-size: 10px;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  line-height: 20px;
  position: absolute;
  text-align: center;
  top: 0px;
}
ol.fancy-ol-bordered li {
  padding-left: 30px;
  position: relative;
  list-style: none outside none;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
ol.fancy-ol-bordered li:last-child {
  border: 0 none;
}

ul.fancy-ul-big li:before {
  font-size: 2rem;
  font-family: 'Font Awesome 5 Solid';
  color: #eaeaea;
  content: "\f138";
  position: absolute;
  left: 0px;
  top:-11px;
}
ul.fancy-ul-big li {
  padding-left: 52.5px;
  position: relative;
  list-style: none outside none;
  margin-bottom: 17.5px;
  padding-bottom: 17.5px;
}
ul.fancy-ul-big li:last-child {
  border: 0 none;
}

ol.fancy-ol-big {
  counter-reset: my-badass-counter;
}


ol.fancy-ol-big li:before {
  background-color: #eaeaea;
  color: #FFFFFF;
  content: counter(my-badass-counter,decimal);
  counter-increment: my-badass-counter;
  font-family: Arial,Verdana,sans-serif;
  font-size: 17.5px;
  left: 0;
  height: 35px;
  width: 35px;
  border-radius: 17.5px;
  line-height: 35px;
  position: absolute;
  text-align: center;
  top: 0px;
}
ol.fancy-ol-big li {
  padding-left: 52.5px;
  position: relative;
  list-style: none outside none;
  margin-bottom: 17.5px;
  padding-bottom: 17.5px;
}
ol.fancy-ol-big li:last-child {
  border: 0 none;
}


.fa-angle-double-right::before {
	padding-right:4px;
}




//files
.custom-file-wrapper{  
	display:flex;
	.custom-file-wrapper--icon{
		flex:0 0 25px;
	}
	.custom-file-wrapper--link{
		flex:1 0 auto;
	}
	
	.size,
	.custom-file-wrapper--link--descr{
		font-size:$font-size-sm;
		color: $text-muted;
	}
}



figure figcaption{
	font-size: $font-size-sm;
	color: $text-muted;
	text-shadow:none;
}











