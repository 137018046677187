//mixins
@mixin fancy-ul{
	li:before {
	  font-size: 14px;
	  font-family: 'Font Awesome 5 Solid';
	  color: #4695d5;
	  content: "\f138";
	  position: absolute;
	  left: -1.5rem;
	}
	li {
	  // padding-left: 21px;
	  // @extend .pl-4;
	  position: relative;
	  list-style: none outside none;
	  // margin-bottom: 7px;
	  // padding-bottom: 7px;
	  @extend .pb-1;
	  @extend .mb-2;
	}
}

@mixin fancy-headline{
	position:relative;
	&:after{
		left: 1px;
		z-index: 1;
		width: 100px;
		height: 2px;
		content: " ";
		bottom: -5px;
		position: absolute;
		background: lighten($primary-color,10%);
	}

} 
