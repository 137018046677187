.alert{
	@extend .alert-success;
	&.alert-error{
		@extend .alert-danger;
	}
	
	padding-left: $spacer * 3;
	position:relative;
	.alert_icon{
		position:absolute;
		left: $spacer;
	}
	
	a{
		@extend .alert-link;
	}
}

.alert-status a,
.alert-success a {
	color: darken(theme-color("success"), 10%);
}

.alert-danger a,
.alert-error a {
	color: darken(theme-color("danger"), 10%);
}

.alert-info a {
	color: darken(theme-color("info"), 10%);
}

.alert-warning a {
	color: darken(theme-color("warning"), 10%);
}