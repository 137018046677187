table#webform-components{
	font-size:$font-size-sm;
	
	a.tabledrag-handle .handle {
		padding:1em;
	}
	
	tr.draggable td:first-child{
		white-space:nowrap;
	}
}


.webform-client-form{


	fieldset.native-fieldset{
		@extend .mb-3;
		@extend .p-3;
		.fieldset-legend{
			font-size:$h4-font-size;
		}
		border-bottom:1px solid $border-color;
		
	
	}
	
	fieldset.row-fieldset{
		.fieldset-wrapper{
			@extend .row;
			.form-item{
				@extend .col-12, .col-lg-6;
			}
		}
		
	}
	
	fieldset.plz-ort-fieldset{
		.fieldset-wrapper{
			@extend .row;
			.form-item:first-child{@extend .col-12, .col-md-4}
			.form-item:last-child{@extend .col-12, .col-md-8}
		}
	}



}




.webform-client-form{
	.form-item{
		@extend .mb-3;
	}
	
	input[type="email"]{
		@extend .form-control;
	}
	
	.webform-container-inline{
		display:flex;
		label{
			flex: 120px 0 0;
		}
	}
	
	label{
		@extend .text-muted;
	}
	
	.form-required{
		@extend .text-danger;
	}
	
	.webform-submit{
		@extend .btn-primary;
		color:white;
	}

	.field-suffix-wrapper{
	
	}
}

//input groups
.field-suffix-wrapper.webform-container-inline .input-group-append,
.field-suffix-wrapper.webform-container-inline .input-group{
	display:flex;
}
.field-suffix-wrapper.webform-container-inline .input-group .form-control{
	margin-top:0;
}

//betrag
.webform-component--spendendaten--spende-art label ~ *,
.webform-component--spendendaten--betrag label ~ *{
	width:200px;
}

//zahlungsweise und kontoinhaber
fieldset.webform-component--zahlungsweise-group--lastschrift-group .webform-component,
.webform-component--zahlungsweise-group--zahlungsweise{
	label{flex: 230px 0 0 !important;}
}

//fieldset im fieldset
.webform-client-form{
	fieldset fieldset.native-fieldset{
		border-bottom: 0 none !important;
		margin-bottom: 0 !important;
		background-color: $gray-100;
	}
}

.webform-component{

	.form-check-label{
		font-weight:bold;
		color:$body-color;
	}
	.description{
		color: $text-muted;
		font-size: $font-size-sm; 
	}

}


@include media-breakpoint-down(xs) {
	.webform-client-form .webform-container-inline{
		display:block;
	}
}






