

form{

	.form-item{
		.form-element-label{
			font-weight:700;
		}
	
	}
	
	select.form-control:not(form-control-sm){
		height: calc(2.25rem + 2px);
		padding: 0.3rem 0.75rem 0.5rem  0.75rem;
	}

	.form-actions.form-wrapper{
		input[type="submit"][id*="submit"]{ @extend .btn-success , .mr-2;}
		input[type="submit"][id*="delete"]{ @extend .btn-danger;}
	}

}

.row.form-row{
	// border-bottom: 1px solid $table-border-color;
	&:last-child{
		border:0 none;
	}
}


.vertical-tabs{
	.vertical-tabs-panes{
		@extend .p-3;
	}

}

#page-node-form a.ckeditor_links,
#page-node-form fieldset.filter-wrapper.form-wrapper {
    // display: none !important;
}


table.tabledrag-processed{
	@extend .table-striped;
}


table#field-content-values{

	div[id*="paragraph-bundle-title"]{
		@extend .mb-4;
		.placeholder{
			font-size:1.25rem;
			font-weight:bold;
		}
		border-bottom:1px dashed $border-color;
	}

	.form-actions.form-wrapper{
		border: 0 none;
		text-align: start;
		margin: 0;
		background-color: transparent;
	}
	
	input[type="submit"][id*="edit"]{
		@extend .btn-sm, .btn-primary, .text-white;
		@extend .mr-2;
	}
	input[type="submit"][id*="remove"]{
		@extend .btn-sm, .btn-danger;
	}
}


#page-node-form #edit-field-content-und-add-more {
    background-color: $gray-200;
    @extend .p-3, .mt-5;
	border-top: 3px solid theme-color("primary");
	div, input{
		display: inline-block;
	}
	input{
		@extend .btn-sm, .btn-success;
		margin-right:1rem;
	}
}


///dateien
.form-field-type-file{
	input[type="submit"][id*="remove"]{
		@extend .btn-sm, .btn-danger;
	}
	.button.edit{
		@extend .btn-sm, .btn-primary;
		@extend .mr-3;
	}
}


.form-type-media{
	.button.browse{
		@extend .btn-sm, .btn-success;
	}
	input[type="submit"][id*="upload"]{
		@extend .btn-sm, .btn-success;
	}
}

.media-widget .media-thumbnail img{
	width: 100px;
	height: 100px;
}
	
	
////////// CKEDiTOR
	
.cke_contents{
	height:400px !important; 
}	
	
body.cke_editable{
 background-color: $content-bg-color;
 padding: 1rem;
 .media-wysiwyg-align-left{
	float:left;
 }

}
	
table.sticky-header{
	display:none;
}	
	
.tabledrag-toggle-weight-wrapper{
	display:none;
}	
	
	
.form-item.form-item-language{
	display:none;
}
	
.node-form > div > .form-item,
.node-form > div > .form-wrapper {
	@extend .mb-2, .pb-2;
	border-bottom:1px solid $border-color;
}  
	
	
	
fieldset.metatags-form{
	/**
	 * Fieldsets.
	 *
	 * Fieldset legends are displayed like containers in Seven. However, several
	 * browsers do not support styling of LEGEND elements. To achieve the desired
	 * styling:
	 * - All fieldsets use 'position: relative'.
	 * - All legend labels are wrapped in a single span.fieldset-legend that uses
	 *   'position: absolute', which means that the LEGEND element itself is not
	 *   rendered by browsers.
	 * - Due to using 'position: absolute', collapsed fieldsets do not have a
	 *   height; the fieldset requires a 'padding-top' to make the absolute
	 *   positioned .fieldset-legend appear as though it would have a height.
	 * - Various browsers are positioning the legend differently if there is a
	 *   'padding-left'/'padding-right' applied on a fieldset and inherit the
	 *   positioning even to absolute positioned elements within; we therefore have
	 *   to apply all padding to the inner .fieldset-wrapper instead.
	 */
	fieldset {
		background-color: #efefef;
		legend{
			width:auto;
			font-weight:bold;
		}
	
	  border: 1px solid #ccc;
	  padding: 2.5em 0 0 0; /* LTR */
	  position: relative;
	  margin: 1em 0;
	}
	fieldset .fieldset-legend {
		font-size:1rem;
	  margin-top: 0.5em;
	  padding-left: 15px; /* LTR */
	  position: relative;
	  text-transform: uppercase;
	}
	fieldset .fieldset-wrapper {
	  padding: 0 13px 13px 15px; /* LTR */
	}
	fieldset.collapsed {
	  background-color: transparent;
	}
	html.js fieldset.collapsed {
	  border-width: 1px;
	  height: auto;
	}
	fieldset fieldset {
	  background-color: #fff;
	}
	fieldset fieldset fieldset {
	  background-color: #f8f8f8;
	}
	


	/**
	 * Collapsible fieldsets.
	 *
	 * @see collapse.js
	 */
	fieldset.collapsible .fieldset-legend {
	  background: url(../../../../../misc/menu-expanded.png) 5px 65% no-repeat; /* LTR */
	  padding-left: 15px; /* LTR */
	}
	fieldset.collapsed .fieldset-legend {
	  background-image: url(../../../../../misc/menu-collapsed.png); /* LTR */
	  background-position: 5px 50%; /* LTR */
	}
	.fieldset-legend span.summary {
	  color: #999;
	  font-size: 0.9em;
	  margin-left: 0.5em;
	}

	fieldset.collapsed .fieldset-wrapper {
		display: none;
	}

}	
	
	
	
	
	
	
	
	
	
	
	