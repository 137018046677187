///view

#custom-header-preview,
#title-bar.custom-header.field-page-header{

    overflow: hidden;

	.titlebar-wrapper{
		
	    display: table;
		width: 100%;
		.titlebar-inner-wrapper{
			display:table-cell;
			@extend .p-5;
		}
	}
	
	
	.titlebar-inner-wrapper .text{
		@extend .my-2;
		@extend .container;
	}
	
	
	.titlebar-inner-wrapper .effect {
		height: 100%;
		position: absolute;
		right: 0;
		top: 0;
		width: 100%;
	}

	
	.titlebar-inner-wrapper .fancy-head {
	  font-size:$h4-font-size;;
	  color:white;
	  font-weight: 600;
	  line-height:$line-height-base;
	  margin-bottom:$paragraph-margin-bottom;
	  i{
		background-color:$primary-color;
		
	  }
	}
	
	.titlebar-inner-wrapper .fancy-subhead {
	  margin: 10px 0 0;
	  // font-size: $h3-font-size;
	  @extend .lead;
	  color:$body-color;
	  // font-weight: 500;
	  line-height:$line-height-base;
	  i{
		background-color:rgba(255, 255, 255, 0.95);
	  }
	}

	.text i{
		font-style:normal;
		background-color:rgba(255, 255, 255, 0.95);
		padding:0.5rem 1rem;
	}
	
}


.hum-colorpicker{ 
    position: absolute;
    z-index: 1000;
	.farbtastic{
		background-color:white;
		border: 1px solid $border-color;
	}
	padding:1rem;
}


#custom-header-form{
	

	#edit-field-page-header-und-0-orientation{
		@extend .form-check-inline;
	}

	.titlebar-inner-wrapper{
		background-color:#efefef !important;
	}
	

	.effect {
	  height: 100%;
	  position: absolute;
	  right: 0;
	  top: 0;
	  width: 100%;
	}
	
	.slider-field{
		@extend .form-group;
		.form-item{
			margin:0;
		}
		.form-element-wrapper{
			display:none;
		}
		
		.ui-slider-horizontal {
			background-color: #DDDDDD;
			background-image: none;
		}
		.ui-slider-range {
			background-color: $primary-color;
			background-image: none;
		}
	
	
	}
	
}


//responsive einstellungen



@include media-breakpoint-down(xs) { 



 }
@include media-breakpoint-down(sm) { 

	#custom-header-preview .titlebar-inner-wrapper,
	#title-bar.custom-header.field-page-header .titlebar-inner-wrapper{
		.fancy-head {
			line-height: 1.3;
			font-size: 2rem;
		}
		.fancy-subhead {}
	}

}

#custom-header-preview .titlebar-inner-wrapper,
#title-bar.custom-header.field-page-header .titlebar-inner-wrapper{
	.fancy-head {}
	.fancy-subhead {}
}
