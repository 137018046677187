//
//
	$gradient: linear-gradient(141deg, 
		$primary-color 0%, 
		darken($primary-color,20%) 100%
	);

	$hero-gradient: linear-gradient(141deg, 
		darken(#3071a9,20%) 0%, 
		darken(#3071a9,50%) 100%
	);

.frontpage-panel-section{
	@extend .my-5;
	&.hero{@extend .mt-0;}
}
 




body.front{
	
	//fancy headlines
	.frontpage-box-inner h2,
	.pane-title{
		@include fancy-headline;
		@extend .mb-5;
	}

}



	
	
	
	
	
	
//////**********************
//// HERO
//////**********************



.frontpage-panel-section.hero{
	
	
	.carousel-caption{
		text-align:left;
		bottom:10%;
		left:10%;
		right:10%;
		h1,
		h3{
			// @extend .display-4;
			// text-shadow: 0px 1px 8px #272727;
			background-color:$primary-color;
			color:white;
			padding:0.5rem 1rem;
			font-size:$h2-font-size;
			font-weight:600;
		}
		p{
			@extend .lead;
			@extend .mb-1, .mb-sm-2, .mb-md-4;
			// text-shadow: 0px 1px 8px #272727;
			background-color:rgba(255, 255, 255, 0.95);
			padding:0.5rem 1rem;
			color:$body-color;

		}

		a:hover{
			text-decoration:none;
		}

		p~a{
			@extend .mt-0;
		}
	}
	
	.carousel-item{
		//bug in firefox?
		//https://github.com/twbs/bootstrap/issues/14713
		backface-visibility: visible;
	}
	
	.carousel-caption{
		bottom: 0;
		top: 0;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-end;
	}
}

@include media-breakpoint-down(md) {
	.frontpage-panel-section.hero{
		.carousel-caption{
			h1,
			h3{
				font-size: $h1-font-size;
			}
		}
	}
}

@include media-breakpoint-down(sm) {
	.frontpage-panel-section.hero{
		.carousel-caption{
			p{
				font-size: $font-size-base;
			}
		}
		img{
			width:auto !important;
			max-width:max-content !important;
			height:17rem !important;
		}
	}
}

@include media-breakpoint-down(xs) {
	.frontpage-panel-section.hero{
		.carousel-caption{
			h1,
			h3{
				font-size: $h1-font-size;
			}
			p{
				display:none;
			}
		}
	}
}


//////**********************
//// BELOW SLIDER
//////**********************

.frontpage-panel-section.below-hero{
	// margin-top: -7rem !important;
	.col.inside{
		@extend .col-12, .col-sm-10, .col-md-8, .mx-auto;
		.below-hero-block{
		  // background-color: $card-bg;
		  // background-clip: border-box;
		  // border: $card-border-width solid $card-border-color;
		  // @include border-radius($card-border-radius);
		  // padding: $card-spacer-x;
		  p{
			font-size:$lead-font-size;
			font-weight:$lead-font-weight;
			strong{
				font-weight:600;
			}
		  }
		}
	}
}




 





//weight toggle
#frontpage-weight-changer{
	@extend .col-12, .col-md-6, .mx-auto;
	a.weight-toggle{
		@extend .btn, .btn-outline-secondary;
		&.weight-toggle-close{
			display:none;
		}
	}
	
	.tabledrag-toggle-weight,
	.view-content{
		display:none;
	}
	
	.view-header{
		@extend .mb-4;
	}
	.view-content{
		@extend .mt-4;
		border: 1px solid $table-border-color;
	}

} 


.frontpage-panel-section.panel-col-last{

	.col-outer{
		@extend .col-12,.col-lg-10, .mx-auto;
		
		> .row{
		    justify-content: space-between;
			// background-color: burlywood;
			// padding: 10px 0;
			&:before{
				content: none;
			}
		}
	}
	
	.col-inner{
		@extend .col-12, .col-md-6, .col-lg-5;
		
		&:first-child{
			@extend .mb-4, .mb-md-0;
		}
		
		// &:first-child{background-color:bisque;}
		// &:last-child{background-color:beige;}
	}
}

//////**********************
//// Supporter Slider
//////**********************
.view-supporter{
	.custom-bs-slider{
		.carousel-item.naked-style,
		.carousel-item.bg-style{
			padding-left:$carousel-control-width;
			padding-right:$carousel-control-width;
			@extend .py-5;
		}
		.carousel-item.naked-style{
			color:$body-color;
			p{
				margin-bottom:0.5rem;
			}
		}
			
		.carousel-naked-style{
			.carousel-control-next,
			.carousel-control-prev{
				color:$body-color;
			}
		}
	}
}

//////**********************
//// Supporter Panel
//////**********************
.frontpage-panel-section.panel-supporter-slider{
	max-width:100%;
	.view-supporter{
		margin: 0 - ($grid-gutter-width / 2);
		background:linear-gradient(135deg, $primary-color 0%, lighten($primary-color,10%) 100%);
	}
	
	.pane-title{
		@extend .col-12, .col-lg-10, .mx-auto, .p-0;
	}

}


//////**********************
//// Supporter Slider Inhalt
//////**********************
.frontpage-panel-section .view-supporter{
	.carousel-item{
		.carousel-item-inner{
			display:flex;
			color:white;
			align-items: center;
			.supporter-image-icon-wrapper{
				flex:220px 0 0;
			}
		}
		
		.supporter-title-add{
			display:flex;
			flex-wrap:wrap;
			align-items:baseline;
			@extend .mb-3;
		}
		
		.supporter-title{
			@extend .display-4;
		}
		.supporter-add{
			@extend .lead;
		}
		
		.supporter-image{
			@extend .mr-3;
			img{
				border-radius:50%;
				box-shadow: 3px 1px 8px rgba(0, 0, 0, 0.38);
			}
		}
		
		.supporter-quote p{
			font-style:italic;
			@extend .lead;
		}
		

		

	}
		
}


@include media-breakpoint-down(md) {
	.frontpage-panel-section .view-supporter{
		.carousel-item-inner{
			align-items: start;
			.supporter-image-icon-wrapper{
				flex-basis: 100px !important;
			}
			.supporter-title{font-size: 1.5rem; font-weight:500; margin-right:1rem;}
			.supporter-add{font-size: 1rem;}
			.supporter-quote p{	
				font-size:1rem;
			}
		}
	}
}





//news

.frontpage-panel-section.news{

	.col-outer{
		@extend .col-12,.col-lg-10, .mx-auto;
	}
	
	.view-neuigkeiten{
		.more-link{
			text-align:center;
			a{
				@extend .btn, .btn-outline-primary;
			}
		}
	}

}


//letzte reihe zweispaltig

.frontpage-panel-section.panel-col-last{
	.col-inner:first-child{
		border-right:1px solid $border-color;
	}


}





.frontpage-read-more{
	@extend .mt-3;
	a{
		@extend .btn, .btn-outline-primary;
	}
}


.view-empty.news{
	display:flex;
	flex-direction:column;
	align-items:center;
	@extend .text-muted; 
	@extend .p-5;
	.text{
		@extend .lead;
		@extend .p-3;
	}
	.icon{
		@extend .p-3;
		@extend .fal, .fa-newspaper, .fa-2x;
	}
}



.spenden-herz-wrapper{
	text-align:center;
}





