 
// For each breakpoint, define the maximum width of the container in a media query + gutter width for the 
@mixin make-pichlmayr-boxed-container-max-widths($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {
  @each $breakpoint, $container-max-width in $max-widths {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      max-width: $container-max-width + $grid-gutter-width / 4;
    }
  }
}


.boxed .layout-center{
	@extend .mr-auto;
	@extend .ml-auto;
	@include make-pichlmayr-boxed-container-max-widths();
}

body.not-front{
	.layout-wrapper{
		@extend .container;
		padding-top: $grid-gutter-width / 2;
		padding-bottom: $grid-gutter-width / 2;
	}
}

.layout-wrapper{
	main{
		@extend .col;
		&.layout-first-sidebar,
		&.layout-both-sidebars{
			@extend .order-1;
			@extend .order-lg-2;
		}
	}
	aside{
		// @extend .col-3;
		&.sidebar-first{
			@extend .order-3;
			@extend .order-lg-1;
			@extend .col-lg-3;
			//bei kleinen viewports ausblenden
			@extend .d-none, .d-lg-block;
		}
		&.sidebar-second{
			@extend .order-2;
			@extend .order-lg-3;
			@extend .col-md-4 , .col-lg-3;
			min-width: 300px;
		}
	}
}