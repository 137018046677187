.carusell-wrapper{
	margin-bottom: $paragraph-margin-bottom;
	text-shadow:0px 0px 3px #000;
	@extend .col-12, .col-md-10, .mx-auto;
	h3{
	    font-weight: 500;
	}
} 


.paragraphs-item-bild-text{
	// @extend .row;
	> .image-wrapper{
		max-width:40%;
		margin-bottom:$paragraph-margin-bottom;
	}
	> .field{
			// @extend .col-12, .col-md-6;
	}


}


.paragraphs-item-bild-text{
	&.ausrichtung-bild-text .image-wrapper{float:left; margin-right:$paragraph-margin-bottom}
	&.ausrichtung-text-bild .image-wrapper{float:right; margin-left:$paragraph-margin-bottom}
}


.field.field-type-paragraphs{
	.field-item{
		@include clearfix;
	}
}

.media-wysiwyg-align-left,
.media-float-left{
	margin-right: $paragraph-margin-bottom;
	margin-bottom: $paragraph-margin-bottom;
}


.node-meta{
	font-size:$font-size-sm;
	color: $text-muted;
	.calendar-icon{
		@extend .fal, .fa-calendar-alt;
	}
	.newspaper-icon{
		@extend .fal, .fa-newspaper;
		@extend .ml-4;
	}
}


.field.field-name-field-files{
	@extend .my-4,.py-2;
	border-color: $border-color;
	border-width:1px 0;
	border-style: solid;
}