

/*
  autocomplete:           'text',
  autocomplete-bg:        'text-bg',
  autocomplete-select:    'white',
  autocomplete-select-bg: 'blue',
*/


.form-autocomplete {
	background-image: url(../images/throbber-inactive.png) !important;
	background-position: 100% center !important;
	background-repeat: no-repeat !important;

  &.throbbing {
	background-image: url(../images/throbber-active.gif) !important;
	background-position: 100% center !important;
	background-repeat: no-repeat !important;
  }
}

// sass-lint:disable no-ids
#autocomplete {
      border: 1px solid;
    overflow: hidden;
    position: absolute;
    z-index: 100;

  ul {
        list-style: none;
    list-style-image: none;
    margin: 0;
    padding: 0;
  }

  li {
       background: $content-bg-color;
    color: $body-color;
    cursor: default;
    white-space: pre;
	@extend .p-2;
  }

  .selected {
    background:  $body-color;
      color: $content-bg-color;
  }
}