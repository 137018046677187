.layout-center{
	background-color:$content-bg-color;
	box-shadow:$content-box-shadow;
}


.page-title-wrapper{
	display:flex;
	h1{
		@include fancy-headline;
		// flex: 1 0 auto;
		// align-self: baseline;
	}
	
	.nav.breadcrumb{
	
	}
	@extend .mb-5;
}




body.node-type-webform.section-kontakt{



	main.layout-second-sidebar,
	aside.sidebar-second{
		@extend .col-12, .col-md-6;
	}

	.breadcrumb-nav{
		display:none !important;
	}
	
	h1.page-title{
		@extend .h2;
	}
	
	aside{
		.block__title{
			padding-top:0 !important;
		}
	}

	.contact-info .map-block{
		display:block;
	}
	
	a.more-kontakt{
		display:none;
	}

}