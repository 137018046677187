.views-supporter-row{
	position:relative;
	display:flex;
	@extend .mt-4;
	@extend .pt-4;
	border-top:1px solid $gray-200;
	.supporter-image-icon-wrapper{
		flex: 200px 0 0;
		@extend .mr-3;
		
		.supporter-image{
			img{
				border-radius: 100px;
			}
		}
	}

	//bei unternehmen, haben die Logos keinen Radius
	&.supporter-type-Unternehmen .supporter-image-icon-wrapper .supporter-image img{
		border-radius:0;
	}

	.supporter-text-wrapper{
		flex: auto 0 1;
	}
	
	.supporter-title-add{
		@extend .mb-3;
		.supporter-title{
			h2{
				font-weight:400;
				color:$primary-color;
			}
		}
		
		.supporter-add{
			// @extend .lead;
			// line-height:$line-height-base / 2;
			font-style:italic;
		}
	}
	
	.supporter-quote p{
		font-weight:bold;;
	}
	
	.ctools-dropbutton-wrapper{
		@extend .pt-4;
		position:absolute;
		top:0;
		right:0;
	}
} 
 

 
 
 //news
	.view-neuigkeiten{

		.views-row{
			@extend .row;
			@extend .mb-5;
			.image{
				img{
					border-radius: $border-radius;
				}
			}
		}
	}
	
	aside .view-neuigkeiten{
		.views-summary{
			a.active{
				font-weight:bold;
			}
		}
	
	}

 .view{
	.node-meta{
		border-top:1px solid $border-color;
		border-bottom:1px solid $border-color;
		@extend .py-1, .my-2;
	}
 }
 
 
.view-newsletter,
.view-pressespiegel{
	.views-row{
		@extend .mb-5;
	}

}
 
 
 .view-pressespiegel{
	.pressespiegel-body{
		p:last-child{
			margin-bottom: $paragraph-margin-bottom / 2;
		}
	}
	.pressespiegel-link{
		a{
			color:$body-color;
			// font-weight:600;
		}
	}
 
 }
 

 
.read-more-body p:last-child,
.read-more-body ~ a,
.read-more-body {
    display: inline;
}
.read-more-body-wrapper{
	margin-bottom: $paragraph-margin-bottom;
} 
 
 
 
 


.pager {
  display: flex;
  // 1-2: Disable browser default list styles
  padding-left: 0; // 1
  list-style: none; // 2
  @include border-radius();
  @extend .justify-content-center;
  @extend .mt-3;
  @extend .mb-5;
}

.pager li {

	@extend .pl-0;
	list-style: none !important;

  &:first-child {
    a {
      margin-left: 0;
      @include border-left-radius($border-radius);
    }
  }
  &:last-child {
    a {
      @include border-right-radius($border-radius);
    }
  }

  &.pager-current,
  &.active a {
    z-index: 2 !important;
    color: $pagination-active-color !important;
    background-color: $pagination-active-bg !important;
    border-color: $pagination-active-border-color !important;
  }

  &.disabled a {
    color: $pagination-disabled-color;
    pointer-events: none;
    // cursor: $cursor-disabled; // While `pointer-events: none` removes the cursor in modern browsers, we provide a disabled cursor as a fallback.
    background-color: $pagination-disabled-bg;
    border-color: $pagination-disabled-border-color;
  }
}


.pager li.pager-current{
  &:first-child {
      margin-left: 0;
      @include border-left-radius($border-radius);
  }
  &:last-child {
      @include border-right-radius($border-radius);
  }
}

.pager li.pager-ellipsis,
.pager li.pager-current,
.pager li a {

  position: relative;
  display: block;
  padding: $pagination-padding-y $pagination-padding-x !important;
  margin-left: -1px;
  line-height: $pagination-line-height;
  color: $pagination-color;
  background-color: $pagination-bg;
  border: $pagination-border-width solid $pagination-border-color;

  @include hover-focus {
    color: $pagination-hover-color;
    text-decoration: none;
    background-color: $pagination-hover-bg;
    border-color: $pagination-hover-border-color;
  }
}

.view-header{
	@extend .alert, .alert-info;
}
.view-filters{
	@extend .alert, .alert-success;
	.views-exposed-widgets{
		display:flex; 
		align-items:bottom;
		align-items: flex-end;
	}
	@extend .mb-5;
}
